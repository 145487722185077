// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-dprime-calculator-tsx": () => import("./../../../src/pages/dprime_calculator.tsx" /* webpackChunkName: "component---src-pages-dprime-calculator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-cnn-face-tsx": () => import("./../../../src/pages/projects/cnn_face.tsx" /* webpackChunkName: "component---src-pages-projects-cnn-face-tsx" */),
  "component---src-pages-projects-loc-familiarity-tsx": () => import("./../../../src/pages/projects/loc_familiarity.tsx" /* webpackChunkName: "component---src-pages-projects-loc-familiarity-tsx" */),
  "component---src-pages-projects-paperaweek-tsx": () => import("./../../../src/pages/projects/paperaweek.tsx" /* webpackChunkName: "component---src-pages-projects-paperaweek-tsx" */),
  "component---src-pages-projects-submm-representations-tsx": () => import("./../../../src/pages/projects/submm_representations.tsx" /* webpackChunkName: "component---src-pages-projects-submm-representations-tsx" */),
  "component---src-pages-projects-topographic-dnn-tsx": () => import("./../../../src/pages/projects/topographic_dnn.tsx" /* webpackChunkName: "component---src-pages-projects-topographic-dnn-tsx" */),
  "component---src-pages-projects-vertices-tsx": () => import("./../../../src/pages/projects/vertices.tsx" /* webpackChunkName: "component---src-pages-projects-vertices-tsx" */)
}

